import React, { useContext, useState } from 'react';
import { Button, Dropdown, Image, Menu } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

// Components
import PasswordUpdate from 'components/PasswordUpdate';
import SvgIcon from 'components/SvgIcon';

// Icons
import arrowIcon from 'assets/images/icons/arrow-thin-down.svg';
import arrowRightIcon from 'assets/images/icons/arrow-right.svg';
import topbarLogo from 'assets/images/topbar-logo.svg';
import gbFlag from 'assets/images/flags/gb.svg';
import itFlag from 'assets/images/flags/it.svg';

// Internals and utils
import { appActionConstants } from 'pages/App/reducer/actions';
import { AppContext } from 'pages/App';
import { getStyledIcon, getSvgStyledIcon } from 'utils/function/styles';
import { COLORS, FE_ROUTES, ICONS, ROLES } from 'utils/global/globalConstants';
import { isThatRole } from 'utils/function/acl';
import LanguageUpdate from 'components/LanguageUpdate';
import { LangProviderContext } from 'components/LangProvider';
import { updateDefaultLanguage } from 'services/user/updateLanguage';
import { changePreferredProfile } from 'services/user/changePreferredProfile';

const Topbar: React.FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const currentLang = useContext(LangProviderContext).language;
  const setLanguage = useContext(LangProviderContext).setLanguage;

  const intl = useIntl();

  // State defintion for manage update password and update language modals
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);

  // Close update password and update language modals callback
  const closePasswordModal = () => setPasswordModalOpen(false);
  const closeLanguageModal = () => setLanguageModalOpen(false);

  const getTopbarName = () => {
    if (state.mySelf && !isThatRole(ROLES.ADMIN)) {
      if (
        state.mySelf._registry.contact &&
        state.mySelf._registry.contact.name
      ) {
        return state.mySelf._registry.contact.name;
      }
    }

    if (state.mySelf && state.mySelf.displayName) {
      return state.mySelf.displayName;
    }

    if (state.mySelf && state.mySelf.username) {
      return state.mySelf.username;
    }

    return 'Utente';
  };

  const isCustomer = isThatRole(ROLES.CUSTOMER);

  return (
    <>
      <Menu className="topbar" inverted size="massive">
        <Menu.Item header as={Link} to="/">
          <Image className="topbar-logo" alt="Logo" src={topbarLogo} fluid />
        </Menu.Item>
        <Menu.Item position="right" className="menu-right-element">
          <Dropdown
            className="footer-select-language standard-dropdown-menu"
            icon={
              <Image
                src={
                  currentLang === 'it-IT' || currentLang === 'it'
                    ? itFlag
                    : gbFlag
                }
              />
            }
            item
            simple
          >
            <Dropdown.Menu>
              <Dropdown.Item
                key="en"
                text="English"
                icon={getStyledIcon(gbFlag)}
                onClick={() => {
                  setLanguage('en');
                  updateDefaultLanguage(state.mySelf.id, 'en', intl);
                }}
              />
              <Dropdown.Item
                key="it"
                text="Italiano"
                icon={getStyledIcon(itFlag)}
                onClick={() => {
                  setLanguage('it-IT');
                  updateDefaultLanguage(state.mySelf.id, 'it', intl);
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
          {!isThatRole(ROLES.CUSTOMER_AGENT) && (
            <Link
              to={
                isCustomer
                  ? currentLang === 'en'
                    ? '/msm_user_manual_customer.pdf'
                    : '/msm_manuale_utente_cliente.pdf'
                  : currentLang === 'en'
                  ? '/msm_user_manual.pdf'
                  : '/msm_manuale_utente.pdf'
              }
              target="_blank"
              className="menu-documentation-download"
            >
              <SvgIcon icon={ICONS.QUESTION_MARK} color={COLORS.SECONDARY} />
            </Link>
          )}
          {isThatRole(ROLES.CUSTOMER_AGENT) && (
            <Link
              to={
                currentLang === 'en'
                  ? '/msm_agent_manual.pdf'
                  : '/msm_agente_manuale.pdf'
              }
              target="_blank"
              className="menu-documentation-download"
            >
              <SvgIcon icon={ICONS.QUESTION_MARK} color={COLORS.SECONDARY} />
            </Link>
          )}
          <Menu.Menu position="right">
            <Dropdown
              className="topbar-dropdown"
              icon={<Image src={arrowIcon} />}
              item
              text={getTopbarName()}
            >
              <Dropdown.Menu>
                {isThatRole(ROLES.ADMIN) ? (
                  <Dropdown.Item
                    key="languageUpdate"
                    text={
                      <FormattedMessage
                        id="topbar.languageUpdate"
                        defaultMessage="Aggiorna lingua"
                      />
                    }
                    icon={getSvgStyledIcon(ICONS.GEAR, COLORS.BLACK)}
                    onClick={() => setLanguageModalOpen(true)}
                  />
                ) : (
                  <Dropdown.Item
                    key="profileSettings"
                    text={
                      <FormattedMessage
                        id="topbar.profile"
                        defaultMessage="Impostazioni profilo"
                      />
                    }
                    icon={getSvgStyledIcon(ICONS.GEAR, COLORS.BLACK)}
                    onClick={() => navigate(FE_ROUTES.MYSELF)}
                  />
                )}
                {state.mySelf?._permissions.isMultiRole && (
                  <Dropdown.Item
                    key="profileSettings"
                    text={
                      isThatRole(ROLES.SUPPLIER) ? (
                        <FormattedMessage
                          id="topbar.changeProfileType.toCustomer"
                          defaultMessage="Passa a profilo cliente"
                        />
                      ) : (
                        <FormattedMessage
                          id="topbar.changeProfileType.toSupplier"
                          defaultMessage="Passa a profilo fornitore"
                        />
                      )
                    }
                    icon={getSvgStyledIcon(ICONS.USER, COLORS.BLACK)}
                    onClick={() => changePreferredProfile(intl)}
                  />
                )}
                {!isThatRole(ROLES.ADMIN) && (
                  <Dropdown.Item
                    key="passwordUpdate"
                    text={
                      <FormattedMessage
                        id="passwordUpdate"
                        defaultMessage="Aggiorna password"
                      />
                    }
                    icon={getSvgStyledIcon(ICONS.SHIELD, COLORS.BLACK)}
                    onClick={() => setPasswordModalOpen(true)}
                  />
                )}
                <Dropdown.Item
                  key="logout"
                  text={<FormattedMessage id="logout" defaultMessage="Esci" />}
                  icon={getStyledIcon(arrowRightIcon)}
                  onClick={() =>
                    dispatch({ type: appActionConstants.REVOKE_SESSION })
                  }
                />
              </Dropdown.Menu>
            </Dropdown>
            {false && (
              <Button className="topbar-button">
                <SvgIcon icon={ICONS.BELL} />
              </Button>
            )}
            {false && (
              <Button className="topbar-button">
                <SvgIcon icon={ICONS.GEAR} />
              </Button>
            )}
          </Menu.Menu>
        </Menu.Item>
      </Menu>

      {/** Modal for update the current user password and current user default language*/}
      <LanguageUpdate isOpen={languageModalOpen} onClose={closeLanguageModal} />
      <PasswordUpdate isOpen={passwordModalOpen} onClose={closePasswordModal} />
    </>
  );
};

export default Topbar;
